img {
  padding-left: 20px;
}

.body-container {
  padding: 20px;
}

.checkbox {
  padding-bottom: 10px;
}

.checkbox label {
  padding-left: 10px;
}

.fixed-width-font {
  font-family: monospace;
}

.button-group {
  padding-top: 10px;
  padding-bottom: 10px;
}
